import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import CalloutGroup from '../components/layout/CalloutGroup';
import Breadcrumbs from '../components/layout/Breadcrumbs';
import DefinitionHeader from '../components/layout/DefinitionHeader';
import DetailsContent from '../components/layout/DetailsContent';

interface ICapabilitiesDetailsProps {
  data: {
    prismic: {
      allCapabilits: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                tags: string;
              };
              title: RichTextBlock[];
            };
          },
        ];
      };
      allCapabilitiess: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
              };
              title: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
              body: {
                primary: {
                  list_group_header: string;
                };
              };
            };
          },
        ];
      };
      _allDocuments: {
        edges: [
          {
            node: {
              image: {
                url: string;
                alt: string;
              };
              title: RichTextBlock[];
              header_content: RichTextBlock[];
              miscellaneous_content: {
                content_title: string;
                miscellaneous_content_body: {
                  text: string;
                  image: {
                    url: string;
                    alt: string;
                  };
                };
              };
              process_content: {
                process_content_body: {
                  text: string;
                  image: {
                    url: string;
                    alt: string;
                  };
                };
              };
              examples_content: {
                examples_content_body: {
                  text: string;
                  image: {
                    url: string;
                    alt: string;
                  };
                };
              };
              additional_content: {
                additional_content_title: string;
                additional_content_body: {
                  text: string;
                  image: {
                    url: string;
                    alt: string;
                  };
                };
              };
              _meta: {
                uid: string;
                tags: string;
              };
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const CapabilitiesDetails: React.FunctionComponent<ICapabilitiesDetailsProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allCapabilitiess.edges.slice(0, 1).pop();
  const detailsDoc = data.prismic._allDocuments.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const details_uid = detailsDoc.node._meta.uid;
  const page_title = detailsDoc.node.title;

  if (!doc) {
    return (
      <LayoutFrame currPath={`/${uid}/${details_uid}/`}>
        <Helmet>
          <title>{RichText.asText(page_title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(page_title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);
  const title = doc.node.title;

  const group = detailsDoc.node._meta.tags[0];
  const aside_items = data.prismic.allCapabilits.edges;
  const list_items = data.prismic._allDocuments.edges;

  const icon_url = detailsDoc.node.image.url;
  const icon_alt = detailsDoc.node.image.alt;
  const header_content = detailsDoc.node.header_content;

  const { button_text, button_style } = footerDoc.node;
  const footer_title = footerDoc.node.title;
  const footer_subtitle = footerDoc.node.subtitle;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={`/${uid}/${details_uid}/`} key={details_uid}>
      <Helmet>
        <title>{RichText.asText(page_title)}</title>
      </Helmet>
      <Breadcrumbs
        title={title}
        title_url={`/${uid}/`}
        subtitle={page_title}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Breadcrumbs>
      <DefinitionHeader
        icon_url={icon_url}
        icon_alt={icon_alt}
        page_title={page_title}
        summary={header_content}
      ></DefinitionHeader>
      <DetailsContent
        title={title}
        header_content={header_content}
        aside_items={aside_items}
        group={group}
        page_color={page_color}
        page_font_color={page_font_color}
        list_items={list_items}
        page_title={page_title}
        parent_slug={uid}
      ></DetailsContent>
      <CalloutGroup
        title={footer_title}
        subtitle={footer_subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default CapabilitiesDetails;

export const query = graphql`
  query CapabilitiesDetailsQuery($type: String, $id: String) {
    prismic {
      allCapabilitiess {
        edges {
          node {
            _meta {
              uid
            }
            title
            primary_page_color
            page_font_color
            body {
              ... on PRISMIC_CapabilitiesBodyContent_list {
                primary {
                  list_group_header
                }
              }
            }
          }
        }
      }
      _allDocuments(type: $type, id: $id) {
        edges {
          node {
            ... on PRISMIC_Capabilit {
              image
              title
              header_content
              miscellaneous_content {
                content_body
                content_title
              }
              process_content {
                process_content_body
              }
              examples_content {
                examples_content_body
              }
              additional_content {
                additional_content_body
                additional_content_title
              }
            }
            _meta {
              uid
              tags
            }
          }
        }
      }
      allCapabilits(sortBy: title_ASC, first: 100) {
        edges {
          node {
            _meta {
              uid
              tags
            }
            title
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
